import React, { useContext } from 'react'
import { LayoutContext } from '@/Layouts/layout/context/layoutcontext.jsx'
import AppTopbar from '@/Layouts/layout/Public/AppTopbar.jsx'

const bg_images = ['portalRover']
const current = bg_images[Math.floor(Math.random() * bg_images.length)]

export default function Guest({ topbar = true, children }) {
    const {
        globalState,
    } = useContext(LayoutContext)

    return (<>
            {topbar && <AppTopbar />}
            <div
                className='flex min-h-screen flex-col items-center pt-6 sm:justify-center sm:pt-0'
                style={{
                    // backgroundSize: 'auto 100%',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'left top',
                    backgroundImage: 'url(/images/hero/' + current + '.webp)',
                }}>
                <div className='my-auto w-full overflow-hidden py-4 shadow-md sm:max-w-md sm:rounded-lg'>
                    {children}
                </div>
            </div>
        </>
    )
}
